import * as React from "react"
import Container from "./Container"
import styled from "styled-components"
import { space, breakpoint, typography } from "../styles/variables"

const ServicesWrapper = styled.div`
  background: #6ae6bf;
  padding: ${space.xxl} 0;
  margin-top: -84px;
  @media (max-width: ${breakpoint.lg}) {
    margin-top: -64px;
  }
`

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${space.xxl};
  @media (max-width: ${breakpoint.lg}) {
    grid-template-columns: 1fr;
    margin-top: ${space.l};
  }
`

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "Focus Fix"
    "Pro Live";
  gap: ${space.xl} ${space.xl};

  @media (max-width: ${breakpoint.sm}) {
    gap: ${space.l} ${space.l};
    grid-template-columns: 1fr;
    grid-template-areas:
      "Focus"
      "Fix"
      "Pro"
      "Live";
  }
`

const Service = styled.div`
  padding-right: ${space.xxl};

  @media (max-width: ${breakpoint.lg}) {
    padding-right: 0;
  }
`

const Focus = styled.div`
  grid-area: Focus;
`
const Fix = styled.div`
  grid-area: Fix;
`

const Pro = styled.div`
  grid-area: Pro;
`

const Live = styled.div`
  grid-area: Live;
`

const Lead = styled.p`
  font-size: ${typography.size.s3};
  line-height: 1.9;
  margin-bottom: ${space.xl};
`

const ContactLink = styled.p`
  text-transform: uppercase;
  font-weight: ${typography.weight.bold};
  cursor: pointer;
  margin-top: ${space.m};

  &:hover {
    text-decoration: underline;
  }
`

const Services = () => {
  const scrollTo = (selector, blockPosition = "start") => {
    const element = document.querySelector(selector)

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: blockPosition,
      })

      return true
    }
  }

  return (
    <>
      <ServicesWrapper id="services">
        <Container>
          <h2>Palvelut</h2>
          <ServicesContainer>
            <Service>
              <Lead>
                Will-Do -valmennuksissa lähtökohtana on yhdessä tekeminen.
                Alkutapaamisen pohjalta määritellään toiveet sekä tavoitteet,
                joissa otetaan huomioon asiakkaan elämäntilanne, käytettävissä
                oleva aika sekä resurssit.
              </Lead>
              <Lead>
              Aloittamalla valmennuksen, liityt samalla Team Will-Do:n jäseneksi. Tiimin tarkoituksena on yhdistää ja motivoida ihmisiä omissa tavoitteissaan. Tiimi on myös  Suomen fitnessurheilu ry:n virallinen tiimi, ja mahdollistaa siten myös fitnesslajeissa kilpailemisen.
              </Lead>              
<Lead>
                Valmennus tähtää pysyviin tuloksiin, joihin päästään
                huomioimalla elämän muuttujat. Asiakas on oman elämänsä
                asiantuntija, ja tämä ajatus onkin keskiössä valmennuksia
                suunniteltaessa. Valmennuksen apuna step2fit sovellus, jonka avulla yhteydenpito on vaivatonta.
</Lead>
              <Lead>
                Valmiit paketit ovat muokattavissa yksilöllisten tarpeiden
                mukaan.
              </Lead>
            </Service>
            <ServicesGrid>
              <Focus>
                <h3>Focus</h3>
                <p>
                  Psyykkisen valmennuksen harjoitusohjelmalla tuetaan arkea.
                </p>
                <p>
                  Valmennuksesta saa työkaluja jaksamisen, hyväksymisen, sekä
                  tasapainoisen elämän löytymisen tueksi.
                </p>
                <p>
                  Harjoituksilla pyritään opitimoimaan olosuhteita, jotta mm.
                  elämäntapamuutokselle olisi mahdollisimman hyvät lähtökohdat.
                  Valmennus perustuu tutkittuun tietoon ja Hyväksymis- ja
                  omistautumisterapian menetelmiin (HOT)
                </p>
                <p>
                  Harjoitusohjelma sisältää alkukartoituksen, ja minimissään 3
                  harjoituskertaa tehtävineen, sekä etätuen.
                </p>
                <p>
                  Hinta alkaen 210€.{" "}
                  <ContactLink onClick={() => scrollTo("#contact")}>
                    Ota yhteyttä
                  </ContactLink>
                </p>
              </Focus>
              <Fix>
                <h3>Fix</h3>
                <p>
                  Fix on valmennus sinulle joka haluat henkilökohtaista valmennusta pitempiaikaisen tavoitteen saavuttamiseen. Kesto vähintään 3 kk.
                </p>
                <p>
                  Valmennus suunnitellaan asiakkaan lähtökohdat, tavoitteet sekä käytössä oleva kapasiteetti huomioon ottaen. Valmennus sisältää alkukartoituksen, henkilökohtaiset treeni ja ravinto-ohjeet, valmennustapaamiset sopimuksen mukaan, tiimipäivät sekä etätuen koko valmennuksen ajaksi.
                </p>
                <p>
                  <ContactLink onClick={() => scrollTo("#contact")}>
                    Ota yhteyttä
                  </ContactLink>
                </p>
              </Fix>
              <Pro>
                <h3>Pro</h3>
                <p>
                 Pro valmennus on fitnesskilpailuun tähtäävä valmennus. Valmennus jakaantuu kehitys, kilpailu ja ylimenokauden kesken, jotka suunnitellaan asiakkaan taustan ja tavoitteen mukaan. Keskiössä myös henkisten resurssien vahvistaminen, jolloin päästään parhaisiin tuloksiin.
                </p>
                <p>
                  Valmennus sisältää alkukartoituksen, henkilökohtaiset treeni ja ravinto-ohjeet, valmennustapaamiset sopimuksen mukaan, poseerausharjoittelua, huoltajan kilpailuissa, tiimipäivät sekä etätuen koko valmennuksen ajaksi.
                </p>
                <p>
                  <ContactLink onClick={() => scrollTo("#contact")}>
                    Ota yhteyttä
                  </ContactLink>
                </p>
              </Pro>
              <Live>
                <h3>Live</h3>
                <p>
                  Tuumasta toimeen! Yksittäinen tunnin tapaaminen ilman
                  sitoutumista. Niille, jotka haluavat kokeilla henkilökohtaista
                  valmennusta, tarvitsevat lisäpotkua treeneihin, apua
                  treenitekniikkaan, poseeraukseen, tai esim. selkeyttää
                  tavoitteita ammattilaisen avulla.
                </p>
                <p>
                  Hinta 85€.{" "}
                  <ContactLink onClick={() => scrollTo("#contact")}>
                    Ota yhteyttä
                  </ContactLink>
                </p>
              </Live>
            </ServicesGrid>
          </ServicesContainer>
        </Container>
      </ServicesWrapper>
    </>
  )
}

export default Services
