import * as React from "react"

import Hero from "../components/Hero"
import SEO from "../components/seo"
import Services from "../components/Services"
import Trainer from "../components/Trainer"
import Layout from "../components/Layout"

const IndexPage = () => (
  <Layout>
    <SEO title="Etusivu" />
    <Hero />
    <Services />
    <Trainer />
  </Layout>
)

export default IndexPage
