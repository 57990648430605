import * as React from "react"
import Container from "./Container"
import styled from "styled-components"
import { space, breakpoint, typography } from "../styles/variables"
import { StaticImage } from "gatsby-plugin-image"

const HeroContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${space.xl} 0;
  position: relative;
  @media (max-width: ${breakpoint.lg}) {
    grid-template-columns: 1fr;
    text-align: center;
    grid-row-gap: ${space.m};
  }
`

const ImageContainer = styled.div`
  z-index: 2;
`

const Wrapper = styled(Container)`
  position: relative;
`

const LogoContainer = styled.div`
  position: absolute;
  top: -40px;
  left: -84px;
  z-index: 1;
  max-width: 1400px;
  pointer-events: none;
  @media (max-width: ${breakpoint.sm}) {
    top: -20px;
    left: 0;
  }
`

const HeroTextContainer = styled.div`
  padding-right: ${space.xxl};
  z-index: 3;
  margin-top: 142px;
  @media (max-width: ${breakpoint.lg}) {
    margin-top: 0;
    padding-right: 0;
    grid-row-gap: ${space.l};
  }

  h1 {
    margin-bottom: 84px;
    @media (max-width: ${breakpoint.lg}) {
      margin-bottom: ${space.xl};
    }
  }
`

const Lead = styled.p`
  font-size: ${typography.size.s3};
  line-height: 1.9;
`

const ContactLink = styled.p`
  text-transform: uppercase;
  font-weight: ${typography.weight.bold};
  cursor: pointer;
  margin-top: ${space.xxl};

  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${breakpoint.lg}) {
    margin-top: ${space.xl};
  }
`

const Header = () => {
  const scrollTo = (selector, blockPosition = "start") => {
    const element = document.querySelector(selector)

    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: blockPosition,
      })

      return true
    }
  }

  return (
    <>
      <Wrapper>
        <LogoContainer>
          <StaticImage
            src="../images/Logo.png"
            quality={80}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Riina"
            placeholder="blurred"
          />
        </LogoContainer>
        <HeroContainer>
          <HeroTextContainer>
            <h1>Ensiaskel muutokseen lähtee halusta, halua seuraa Teot.</h1>
            <Lead>
              Kokonaisvaltainen hyvinvointi syntyy levosta, liikunnasta,
              ravinnosta ja henkisestä energiasta. Hyvä valmennus puolestaan
              tähtää muutokseen, joka saavutetaan parhaiten muovaamalla
              ajattelumalleja ja olosuhteita. Valmennukseni avulla löydät uusia,
              käytännöllisiä tapoja, joilla parannat hyvinvointiasi ja saavutat
              pysyviä tuloksia.
            </Lead>
            <ContactLink onClick={() => scrollTo("#contact")}>
              varaa aika maksuttomaan konsultaatioon
            </ContactLink>
          </HeroTextContainer>
          <ImageContainer>
            <StaticImage
              src="../images/Riina1.png"
              quality={80}
              layout="fullWidth"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Riina"
              placeholder="blurred"
            />
          </ImageContainer>
        </HeroContainer>
      </Wrapper>
    </>
  )
}

export default Header
