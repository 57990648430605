import * as React from "react"
import Container from "./Container"
import styled from "styled-components"
import { space, breakpoint, typography } from "../styles/variables"
import { StaticImage } from "gatsby-plugin-image"

const ServicesWrapper = styled.div`
  background: #fdf151;
  padding: ${space.xxl} 0 0 0;
  position: relative;
  z-index: -1;
`

const Stats = styled.div`
  margin-top: ${space.xxl};

  @media (max-width: ${breakpoint.lg}) {
    margin-top: ${space.xl};
  }
`

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${space.xxl};

  @media (max-width: ${breakpoint.lg}) {
    grid-template-columns: 1fr;
    margin-top: ${space.l};
    gap: ${space.xl} 0;
    display: flex;
    flex-direction: column;
  }
`

const TrainerBio = styled.div`
  padding-right: ${space.xxl};

  @media (max-width: ${breakpoint.lg}) {
    padding-right: 0;
  }
`

const TrainerPic = styled.div`
  padding-right: ${space.xxl};
  z-index: 2;
  align-self: flex-end;
  @media (max-width: ${breakpoint.lg}) {
    padding-right: 0;
    align-self: normal;
  }
`
const LogoContainer = styled.div`
  position: absolute;
  top: 300px;
  left: 15%;
  z-index: 1;
  max-width: 1400px;
  @media (max-width: ${breakpoint.sm}) {
    top: 75%;
    left: 0;
  }
`

const Trainer = () => (
  <>
    <ServicesWrapper id="trainer">
      <Container>
        <h2>Valmentaja</h2>
        <LogoContainer>
          <StaticImage
            src="../images/Logo.png"
            quality={80}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Riina"
            placeholder="blurred"
          />
        </LogoContainer>
        <ServicesContainer>
          <TrainerBio>
            <p>
              Minulla on vahva halu auttaa ja motivoida. Työurani aikana olen
              toiminut monenlaisissa työtehtävissä. Pidän ihmisten kanssa
              työskentelystä, ja läheisten hyvinvointi on ollut minulle aina
              tärkeää.
            </p>{" "}
            <p>
              Valmennus on aina kokonaisvaltainen prosessi, jossa suuressa
              osassa on henkiset voimavarat. Jokainen valmennettava on yksilö,
              yksilöllisine tarpeineen. Haluankin tarjota räätälöityä
              valmennusta, jossa jokainen ihminen nähdään kokonaisuutena.
            </p>{" "}
            <p>
              Usein ulkoisten tekijöiden muuttaminen on monelle se ensimmäinen
              sysäys elämäntapojen muutokseen, mutta tärkeintä on olla sinut
              sisäisen itsensä kanssa. Pelkkä ulkoinen kauneus ja voima ei
              riitä, jos haluat voida kokonaisvaltaisesti hyvin. Loppujen
              lopuksi kaikki on kiinni siitä, mitä sinä haluat. Se että työ
              tehdään itse ei kuitenkaan tarkoita, että tavoite pitäisi
              saavuttaa yksin. Me kaikki tarvitsemme tukea.
            </p>{" "}
            <p>
              Urheilu-urani kautta minulle on kertynyt roppakaupalla
              omakohtaista kokemusta valmennettavana olemisesta, treenaamisesta,
              kehonmuokkauksesta, sekä siitä miten kaikki tuo yhdistetään
              työssäkäymiseen ja perhe elämään. Kilpailuihin valmistavat
              prosessit ovat opettaneet minulle paljon sellaista, jota ei voi
              lukea oppikirjoista, ja juuri nuo opit ovat suurin vahvuuteni
              valmentajana. Kuvailisin itseäni sanoilla, huumorintajuinen,
              energinen, tarkka, reilu, välittävä ja kannustava.
            </p>{" "}
            <p>
              Jos sinulta löytyy halua voimistua niin sisäisesti kuin
              ulkoisesti, lähdetään töihin yhdessä. You Will-Do, we Will -Do!
            </p>{" "}
            <Stats>
              {" "}
              <p>
                <h4>Sijoitukset Bodyfitness (>168, Masters):</h4>
              <strong>2022</strong> Masters SM 1, Masters EM 1, Overall 1
                <strong>2019</strong> Masters MM 3, Nordic Fitness Expo SM 3,
                Nordic cup 4, Ideapark Fitness Cup 1, Masters 1{" "}
                <strong>2018</strong> EM 5, Oslo GP 2&3, Fitness Classic 2,
                Masters SM 2 <strong>2017</strong> EM 7, Fitness classic 1,
                Masters SM 2 <strong>2015</strong> SM 12
              </p>{" "}
              <br />
              <p>
                <h4>Sertifikaatit:</h4>
                Trainer4you Psychic Coach, NHA Personal Trainer (ACE), Trainer
                Lab Basic
              </p>{" "}
            </Stats>
          </TrainerBio>
          <TrainerPic>
            <StaticImage
              src="../images/Riina2.png"
              quality={80}
              layout="fullWidth"
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Riina"
              placeholder="blurred"
            />
          </TrainerPic>
        </ServicesContainer>
      </Container>
    </ServicesWrapper>
  </>
)

export default Trainer
